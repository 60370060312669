






//@ts-nocheck

import {Vue, Component, Prop, Watch} from 'vue-property-decorator'


@Component({
  components: {

  }
})
export default class VincereCallback extends Vue {
  async mounted() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      // @ts-ignore
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const id = params.state;
    const code = params.code;
    await this.$api.maintenance.vincere.ReauthoriseVincere(id, code);

    await this.$router.push({
      name: "maintenance-vincereinstance",
      params: {
        identifier: id
      }
    })
  }
}
